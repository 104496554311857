import logo from './logo.svg';
import { pdfjs } from 'react-pdf'
import { authProtectedRoutes } from "./routes"
import Routes from "./routes/Routes"

import "./App.css"
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import RetrieveReport from './pages/RetrieveReport';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

function App() {
  return (
    <Router>
    <div className="m-4 overflow-hidden mt-12 pt-4">
      <Switch>
        <Route exact path='/' component={RetrieveReport} />

        {/* { authProtectedRoutes.map((route, idx) => (
            <Routes
              path={route.path}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
        ))} */}

      </Switch>
    </div>
  </Router>
  )
}

export default App;
