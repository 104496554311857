import React, { useEffect, useState } from 'react'
import { MasterLoginApi, ReportSearchApi } from '../api/ApiHandler'
import ReportPreview from "../components/ReportPreview"

import { VscPreview } from "react-icons/vsc"

import styled, { keyframes } from "styled-components"
import { fadeInDown, fadeIn } from 'react-animations'

const { REACT_APP_EMAIL, REACT_APP_PASSWORD } = process.env

const fadeInDownAnimation = keyframes`${fadeInDown}`
const FadeInDownDiv = styled.div`animation: 1s ${ fadeInDownAnimation };`

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`animation: 1s ${ fadeInAnimation };`

const initialState = {
  error: "",
  reportId: "",
  processing: "",
  reportToView: "https://dummyimage.com/400x400/d9d9d9/000000&text=Select+Report+to+preview",
  totalNodes: 0,
  pdfModalWidth: 465,
  pdfModalHeight: 465,
  pdfPreviewWidth: 400,
  pdfPreviewHeight: 300,
}

const RetrieveReport = () => {
  const [error, setError] = useState(initialState.error)
  const [totalNodes, setTotalNodes] = useState(initialState.totalNodes)
  const [processing, setProcessing] = useState(initialState.processing)
  const [reportId, setReportId] = useState(initialState.reportId)
  const [reportToView, setReportToView] = useState(initialState.reportToView)

  useEffect(() => {
    const user = {
      email:REACT_APP_EMAIL,
      password: REACT_APP_PASSWORD
    }
    MasterLoginApi(user)
    .then(res => {
      if(res.message !== "wrong username or password")
        localStorage.setItem("token", res.token)
      else
        alert("Wrong Username or Password. Try Again...")
    })
    .catch(err => {
      alert("Error: " + err)
    })
  }, [])

  const handleTextChange = (e) => {
    const { value } = e.target
    setReportId(value)
  }

  const handleReportBtnClick = () => {
    if(reportId === "") {
      setError("* Report ID missing")
    } else {
      setProcessing(true)
      setError("")

      const reportFormData = new FormData()
      reportFormData.append("report_id", reportId)

      ReportSearchApi(reportFormData)
      .then(res => {
        if(res.message === 'report Found') {
          console.log(res)
          setReportToView(res.report)
          setTotalNodes(res.totalNodes)
          setProcessing(false)
        } else if(res.message === 'No report for this id') {
          setProcessing(false)
          setError("No Report found against provided Id")
        } else if(res.message === "Report id is incorrect") {
          setProcessing(false)
          setError("Report Id is incorrect")
        }
      })
      .catch(err => {
        setProcessing(false)
        console.log(err)
        alert(err)
      })
    }
  }

  const handleCloseModal = () => {
    document.getElementById("modal").classList.remove("block")
    document.getElementById("modal").classList.add("hidden")
  }

  return (
    <FadeInDiv className="flex flex-col justify-center items-center">
      <p className="font-semibold text-2xl mb-3 text-gray-700 select-none">PAKISTAN VACCINATED PASSPORT</p>
      {/* <Logout /> */}
      <FadeInDownDiv className="flex-1">
        <input className="text-center hover:border-green-600 transform duration-300 border border-transparent rounded-xl p-1 outline-none focus:border-green-700" placeholder="Enter Report ID..." type="text" onChange={handleTextChange} value={reportId} />
      </FadeInDownDiv>
      <FadeInDiv className="errorTextDiv">
        { error === "" ?
          <span className="text-white">*</span> :
          <span className="text-red-600 font-bold">{error === "" ? '' : error}</span>
        }
      </FadeInDiv>
      <FadeInDiv>
        <button className="bg-white my-1 focus:outline-none focus:bg-blue-900 focus:text-white text-blue-700 font-semibold p-2 rounded-lg border-blue-800 border hover:ease-in-out duration-300 hover:text-white hover:bg-blue-600" onClick={handleReportBtnClick}><VscPreview size={22} className="inline mr-2 mb-0.5" />{processing ? 'Processing...' : 'Get Report'}</button>
      </FadeInDiv>
      <ReportPreview report={reportToView} width={initialState.pdfPreviewWidth} height={initialState.pdfPreviewHeight} />
      <FadeInDiv>
        { processing ?
          <p className="text-blue-700 font-bold">Processing...</p> :
          reportToView.includes("dummyimage") ?
          <></> :
          <p className="text-green-700 font-bold">This Report has been verified by {3} nodes</p>
        }
      </FadeInDiv>
     </FadeInDiv>
  )
}

export default RetrieveReport