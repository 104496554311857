const { REACT_APP_BASE_URL } = process.env

export const MasterLoginApi = async (user) => {
  const response = await fetch(REACT_APP_BASE_URL + "/login", {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(user),
  })
  return await response.json()
}

export const MasterLogoutApi = async () => {
  const response = await fetch(REACT_APP_BASE_URL + "/logout", {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  })
  return await response.json()
}

export const ReportSearchApi = async (reportData) => {
  const response = await fetch(REACT_APP_BASE_URL + "/report/search", {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    },
    body: reportData,
  })
  return await response.json()
}